<template>
  <form class="form p2p-pay-form" @submit.prevent>
    <div class="form__container form__container--p2p p2p-pay-form__container">
      <div class="p2p-pay-form__requisites">
        <picture v-if="paymentMethod.img">
          <img
            :alt="paymentResponse?.info?.payment?.bank_group?.title"
            :src="`${url}${paymentMethod.img}`"
          />
        </picture>
        <span v-else-if="paymentMethod.bankTitle">{{
          paymentMethod.bankTitle
        }}</span>
        <span>{{ paymentMethod.title }}</span>
        <p class="p2p-pay-form__number">
          <span>{{ paymentMethod.data }}</span>
          <button @click.prevent="copyToClipboard">
            <svg>
              <use xlink:href="#copy"></use>
            </svg>
          </button>
        </p>
        <span v-if="paymentMethod.holder">
          {{ paymentMethod.holder }}
        </span>
      </div>
      <span v-if="isCopied" class="p2p-pay-form__copied">Скопировано!</span>
      <div class="p2p-pay-form__info p2p-pay-form__info--error">
        <VAlert />
        <p>
          Переведите на указанный номер
          <span>в точности указанную сумму</span>, одним переводом
        </p>
      </div>
      <div class="p2p-pay-form__info p2p-pay-form__info--warning">
        <VAlert />
        <p>Дождитесь обработки операции</p>
      </div>
      <div class="p2p-pay-form__info p2p-pay-form__info--warning">
        <VAlert />
        <p>
          Обратите внимание, номер {{ paymentMethod.text }} меняется с каждой
          заявкой
        </p>
      </div>
      <div class="p2p-pay-form__countdown">
        Осталось времени -&nbsp;
        <v-countdown
          :date-to="paymentMethod.countdown"
          class="p2p-pay-form__timer"
        />
      </div>
      <v-divider class="divider-section" />
      <div class="p2p-pay-form__buttons">
        <p>Если вы отправили средства, нажмите кнопку “Я оплатил”</p>
        <VButton
          :loading="loading"
          class="form__submit p2p-pay-form__submit"
          @click="changePaymentStatus('confirm')"
        >
          Я оплатил
        </VButton>
        <button
          v-if="!hasAppeals"
          class="p2p-pay-form__button"
          @click="$emit('openAppeal')"
        >
          Обратиться в поддержку
        </button>
        <button
          v-else
          class="p2p-pay-form__button"
          @click="$emit('openAppealStatus')"
        >
          Посмотреть статус апелляции
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { TApproveForm } from "@/types/payment";
import VAlert from "@/components/icons/VAlert.vue";
import VButton from "@/components/VButton.vue";
import VDivider from "@/components/VDivider.vue";
import VCountdown from "@/components/VCountdown.vue";
import { useApi } from "@/plugins/api";
import { usePayment } from "@/use/usePayment";

defineProps({
  hasAppeals: {
    type: Boolean,
    default: false,
  },
});

defineEmits<{
  (e: "openAppeal"): void;
  (e: "openAppealStatus"): void;
}>();

const { paymentResponse, updatePayment } = usePayment();

const payMethodsMap = {
  card: {
    title: "Номер карты",
    text: "карты",
    data: paymentResponse.value?.info?.p2p_card_data?.target_card_number,
    countdown: paymentResponse.value?.info?.p2p_card_data?.valid_till,
    img: paymentResponse.value?.info?.payment?.bank_group?.logo2,
    bankTitle: paymentResponse.value?.info?.payment?.bank_group?.title,
    holder: paymentResponse.value?.info?.p2p_card_data?.fio,
  },
  phone: {
    title: "Номер телефона",
    text: "телефона",
    data:
      paymentResponse.value?.info?.p2p_wallet_data?.means_type === "phone" &&
      paymentResponse.value?.info?.p2p_wallet_data?.number,
    countdown: paymentResponse.value?.info?.p2p_wallet_data?.valid_till,
    img: paymentResponse.value?.info?.payment?.bank_group?.logo2,
    bankTitle: paymentResponse.value?.info?.payment?.bank_group?.title,
    holder: paymentResponse.value?.info?.p2p_wallet_data?.fio,
  },
  account: {
    title: "Номер расчетного счета",
    text: "расчетного счета",
    data:
      paymentResponse.value?.info?.p2p_wallet_data?.means_type === "account" &&
      paymentResponse.value?.info?.p2p_wallet_data?.number,
    countdown: paymentResponse.value?.info?.p2p_wallet_data?.valid_till,
    img: paymentResponse.value?.info?.payment?.bank_group?.logo2,
    bankTitle: paymentResponse.value?.info?.payment?.bank_group?.title,
  },
};

const paymentMethod = computed(() => {
  return Object.values(payMethodsMap).find((method) => !!method.data);
});

const url = computed(() => process.env.VUE_APP_API_URL);

const form = ref<TApproveForm>({
  tx: paymentResponse.value?.info.tx as string,
  approved_status:
    paymentResponse.value?.info?.payment?.approved_status ?? "process",
});
const loading = ref(false);

const isCopied = ref(false);
const copyToClipboard = async () => {
  await navigator.clipboard.writeText(paymentMethod.value.data ?? "");
  isCopied.value = true;
  setTimeout(() => (isCopied.value = false), 2000);
};

const { payment } = useApi();
const changePaymentStatus = async (status: "confirm" | "cancel") => {
  try {
    loading.value = true;
    form.value.approved_status = status;
    const { data } = await payment.approveP2P(form.value);
    await updatePayment(data.info.tx);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss">
@import "@scss/utils";

.p2p-pay-form {
  &__container {
    gap: 16px !important;
  }

  &__requisites {
    display: grid;
    gap: 8px;
    padding: 16px;
    border-radius: 10px;
    background-color: $wrapper-bg;

    > picture {
      display: grid;
      justify-content: start;
      height: 24px;
    }

    > span {
      color: $alter-text;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__number {
    display: flex;
    color: $alter-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    column-gap: 8px;

    > button {
      svg {
        width: 20px;
        height: 20px;
      }

      &:hover svg {
        fill: $btn-bg-main;
      }
    }
  }

  &__copied {
    justify-self: center;
    margin-bottom: 20px;
    color: $input-text-placeholder;
    font-size: 14px;
  }

  &__info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    align-items: start;
    padding: 16px;
    border-radius: 10px;
    background-color: rgba($error, 0.1);

    > svg {
      color: $error;
    }

    > p {
      font-size: 12px;
      line-height: 18px;

      > span {
        font-weight: 600;
      }
    }

    &--warning {
      background-color: rgba($warning, 0.1);

      > svg {
        color: $warning;
      }
    }
  }

  &__countdown {
    display: flex;
    justify-content: center;
    color: $alter-text;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;

    & .p2p-pay-form__timer {
      font: inherit;
    }
  }

  &__buttons {
    > p {
      margin-bottom: 16px;
      color: $alter-text;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }

  &__submit {
    margin-bottom: 16px !important;
  }

  &__button {
    display: grid;
    place-content: center;
    width: 100%;
    padding: 14px;
    color: $tp-btn-text-main;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.3px;
    cursor: pointer;
  }
}
</style>
