import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx } from "vue"

import OperationWrapper from "@/components/OperationWrapper.vue";
import { OperationType } from "@/helpers/enums";
import { computed, onBeforeMount, ref, watch } from "vue";
import { type TAppeal } from "@/types/payment";
import H2HP2PBanks from "@/components/form/H2HP2P/H2HP2PBanks.vue";
import H2HP2PPayForm from "@/components/form/H2HP2P/H2HP2PPayForm.vue";
import OperationFail from "@/components/OperationFail.vue";
import OperationSuccess from "@/components/OperationSuccess.vue";
import OperationProcess from "@/components/OperationProcess.vue";
import { useApi } from "@/plugins/api";
import H2HP2PSupport from "@/components/form/H2HP2P/H2HP2PSupport.vue";
import H2HP2PAppealStatus from "@/components/form/H2HP2P/H2HP2PAppealStatus.vue";
import TheLoader from "@/components/TheLoader.vue";
import { usePayment } from "@/use/usePayment";
import { FAIL_STATUES } from "@/helpers/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'H2HP2PFlow',
  emits: ["changeWrapper"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { paymentResponse, price } = usePayment();

const isAppealFormOpen = ref(false);
const isAppealStatusFormOpen = ref(false);
const appeals = ref<TAppeal[]>([]);
const hasAppeals = computed(() => !!appeals.value.length);
const isLoading = ref(false);

const getAppeals = async () => {
  try {
    isLoading.value = true;
    const { data } = await payment.appeals(
      paymentResponse.value?.info.tx as string
    );
    appeals.value = data;
    isAppealStatusFormOpen.value = data.length !== 0;
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

watch(
  isAppealFormOpen,
  (value) => {
    emit("changeWrapper", value);
  },
  { immediate: true }
);

const isLoaderState = computed(() =>
  ["process", "queue", "waiting", "unknown", "hold"].includes(
    paymentResponse.value?.info?.payment?.status ?? ""
  )
);

const isFailState = computed(() =>
  ["unknown", ...FAIL_STATUES].includes(
    paymentResponse.value?.info?.payment?.status ?? ""
  )
);

const { payment } = useApi();

onBeforeMount(getAppeals);

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createBlock(TheLoader, { key: 0 }))
    : (
      isAppealStatusFormOpen.value &&
      hasAppeals.value &&
      _unref(paymentResponse).info.payment.status !== 'success'
    )
      ? (_openBlock(), _createBlock(H2HP2PAppealStatus, {
          key: 1,
          appeal: appeals.value[0],
          onClose: _cache[0] || (_cache[0] = ($event: any) => (isAppealStatusFormOpen.value = false))
        }, null, 8, ["appeal"]))
      : (isAppealFormOpen.value)
        ? (_openBlock(), _createBlock(H2HP2PSupport, {
            key: 2,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (isAppealFormOpen.value = false)),
            onUpdateAppeals: getAppeals
          }))
        : (_unref(paymentResponse).info.payment.status === 'success')
          ? (_openBlock(), _createBlock(OperationSuccess, {
              key: 3,
              url: _unref(paymentResponse).info.payment.success_url
            }, null, 8, ["url"]))
          : (isFailState.value)
            ? (_openBlock(), _createBlock(OperationFail, {
                key: 4,
                "has-appeals": hasAppeals.value,
                url: _unref(paymentResponse).info.payment.fail_url,
                onOpenAppeal: _cache[2] || (_cache[2] = ($event: any) => (isAppealFormOpen.value = true)),
                onOpenAppealStatus: _cache[3] || (_cache[3] = ($event: any) => (isAppealStatusFormOpen.value = true))
              }, null, 8, ["has-appeals", "url"]))
            : (
      isLoaderState.value &&
      _unref(paymentResponse).info.payment.approved_status === 'confirm'
    )
              ? (_openBlock(), _createBlock(OperationProcess, {
                  key: 5,
                  "has-appeals": hasAppeals.value,
                  onOpenAppeal: _cache[4] || (_cache[4] = ($event: any) => (isAppealFormOpen.value = true)),
                  onOpenAppealStatus: _cache[5] || (_cache[5] = ($event: any) => (isAppealStatusFormOpen.value = true))
                }, null, 8, ["has-appeals"]))
              : (
      ['process', 'create'].includes(
        _unref(paymentResponse)?.info?.payment?.status ?? ''
      )
    )
                ? (_openBlock(), _createBlock(OperationWrapper, {
                    key: 6,
                    price: _unref(price),
                    type: _unref(OperationType).PAYMENT
                  }, {
                    default: _withCtx(() => [
                      (_unref(paymentResponse).info.payment.status === 'create')
                        ? (_openBlock(), _createBlock(H2HP2PBanks, { key: 0 }))
                        : (
        _unref(paymentResponse).info.payment.status === 'process' &&
        (_unref(paymentResponse).info.p2p_card_data ||
          _unref(paymentResponse).info.p2p_wallet_data)
      )
                          ? (_openBlock(), _createBlock(H2HP2PPayForm, {
                              key: 1,
                              "has-appeals": hasAppeals.value,
                              onOpenAppeal: _cache[6] || (_cache[6] = ($event: any) => (isAppealFormOpen.value = true)),
                              onOpenAppealStatus: _cache[7] || (_cache[7] = ($event: any) => (isAppealStatusFormOpen.value = true))
                            }, null, 8, ["has-appeals"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["price", "type"]))
                : _createCommentVNode("", true)
}
}

})