<template>
  <TheLoader v-if="isLoading" />
  <H2HP2PAppealStatus
    v-else-if="
      isAppealStatusFormOpen &&
      hasAppeals &&
      paymentResponse.info.payment.status !== 'success'
    "
    :appeal="appeals[0]"
    @close="isAppealStatusFormOpen = false"
  />
  <H2HP2PSupport
    v-else-if="isAppealFormOpen"
    @close="isAppealFormOpen = false"
    @update-appeals="getAppeals"
  />
  <operation-success
    v-else-if="paymentResponse.info.payment.status === 'success'"
    :url="paymentResponse.info.payment.success_url"
  />
  <operation-fail
    v-else-if="isFailState"
    :has-appeals="hasAppeals"
    :url="paymentResponse.info.payment.fail_url"
    @open-appeal="isAppealFormOpen = true"
    @open-appeal-status="isAppealStatusFormOpen = true"
  />
  <operation-process
    v-else-if="
      isLoaderState &&
      paymentResponse.info.payment.approved_status === 'confirm'
    "
    :has-appeals="hasAppeals"
    @open-appeal="isAppealFormOpen = true"
    @open-appeal-status="isAppealStatusFormOpen = true"
  />
  <operation-wrapper
    v-else-if="
      ['process', 'create'].includes(
        paymentResponse?.info?.payment?.status ?? ''
      )
    "
    :price="price"
    :type="OperationType.PAYMENT"
  >
    <H2HP2PBanks v-if="paymentResponse.info.payment.status === 'create'" />
    <H2HP2PPayForm
      v-else-if="
        paymentResponse.info.payment.status === 'process' &&
        (paymentResponse.info.p2p_card_data ||
          paymentResponse.info.p2p_wallet_data)
      "
      :has-appeals="hasAppeals"
      @open-appeal="isAppealFormOpen = true"
      @open-appeal-status="isAppealStatusFormOpen = true"
    />
  </operation-wrapper>
</template>

<script lang="ts" setup>
import OperationWrapper from "@/components/OperationWrapper.vue";
import { OperationType } from "@/helpers/enums";
import { computed, onBeforeMount, ref, watch } from "vue";
import { type TAppeal } from "@/types/payment";
import H2HP2PBanks from "@/components/form/H2HP2P/H2HP2PBanks.vue";
import H2HP2PPayForm from "@/components/form/H2HP2P/H2HP2PPayForm.vue";
import OperationFail from "@/components/OperationFail.vue";
import OperationSuccess from "@/components/OperationSuccess.vue";
import OperationProcess from "@/components/OperationProcess.vue";
import { useApi } from "@/plugins/api";
import H2HP2PSupport from "@/components/form/H2HP2P/H2HP2PSupport.vue";
import H2HP2PAppealStatus from "@/components/form/H2HP2P/H2HP2PAppealStatus.vue";
import TheLoader from "@/components/TheLoader.vue";
import { usePayment } from "@/use/usePayment";
import { FAIL_STATUES } from "@/helpers/constants";

const emit = defineEmits<{
  (e: "changeWrapper", value: boolean): void;
}>();

const { paymentResponse, price } = usePayment();

const isAppealFormOpen = ref(false);
const isAppealStatusFormOpen = ref(false);
const appeals = ref<TAppeal[]>([]);
const hasAppeals = computed(() => !!appeals.value.length);
const isLoading = ref(false);

const getAppeals = async () => {
  try {
    isLoading.value = true;
    const { data } = await payment.appeals(
      paymentResponse.value?.info.tx as string
    );
    appeals.value = data;
    isAppealStatusFormOpen.value = data.length !== 0;
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

watch(
  isAppealFormOpen,
  (value) => {
    emit("changeWrapper", value);
  },
  { immediate: true }
);

const isLoaderState = computed(() =>
  ["process", "queue", "waiting", "unknown", "hold"].includes(
    paymentResponse.value?.info?.payment?.status ?? ""
  )
);

const isFailState = computed(() =>
  ["unknown", ...FAIL_STATUES].includes(
    paymentResponse.value?.info?.payment?.status ?? ""
  )
);

const { payment } = useApi();

onBeforeMount(getAppeals);
</script>
